import React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-bootstrap"
import { Trans } from "@lingui/macro"

import { Title, Box, Text } from "../../components/Core"

import logo_apache from "../../assets/image/svg/logos/logo_apache.svg"
import logo_aws from "../../assets/image/svg/logos/logo_aws.svg"
import logo_azure from "../../assets/image/svg/logos/logo_azure.svg"
import logo_dask from "../../assets/image/svg/logos/logo_dask.svg"
import logo_hadoop from "../../assets/image/svg/logos/logo_hadoop_short.svg"
import logo_kafka from "../../assets/image/svg/logos/logo_kafka.svg"
import logo_pandas from "../../assets/image/svg/logos/logo_pandas.svg"
import logo_pulumi from "../../assets/image/svg/logos/logo_pulumi.svg"
import logo_python from "../../assets/image/svg/logos/logo_python.svg"
import logo_pytorch from "../../assets/image/svg/logos/logo_pytorch.svg"
import logo_scikit from "../../assets/image/svg/logos/logo_scikit_small.svg"
import logo_tensorflow from "../../assets/image/svg/logos/logo_tensorflow.svg"

import logo_docker from "../../assets/image/png/logos/logo_docker.png"
import logo_git from "../../assets/image/png/logos/logo_git.png"
import logo_kubernetes from "../../assets/image/png/logos/logo_kubernetes.png"



const Brand = styled(Box)`
  filter: grayscale(20%) opacity(79%);
  transition: all 0.3s ease-out;
  &:hover {
  filter: grayscale(10%) opacity(100%);
  }
  &> img {
    max-height:60px;
  }
`
const Logo = ({
    imgfile,
    name = "",
    link = "",
    className = "",
}) => {
    return (
        <img
            src={imgfile}
            alt={name}
            title={name}
            className={`tw-w-24 tw-h-24 tw-object-contain tw-place-self-center tw-opacity-80 tw-grayscale-[100%] hover:tw-filter-none  tw-transition tw-duration-300 tw-ease-in ${className}`}
        >
        </img >
    )
}



const Content = () => (
    <>

        <div className="tw-grid tw-grid-cols-3 sm:tw-grid-cols-4 lg:tw-grid-cols-6 tw-content-evenly tw-gap-8 md:tw-gap-10 tw-p-6 md:tw-px-5 tw-m-3">

            <Logo imgfile={logo_aws} name="Amazon Web Services (AWS)" />
            <Logo imgfile={logo_azure} name="Microsoft Azure" className="tw-h-20" />
            <Logo imgfile={logo_python} name="Python" />
            <Logo imgfile={logo_pytorch} name="PyTorch" className="tw-h-20" />
            <Logo imgfile={logo_tensorflow} name="Tensorflow" />
            <Logo imgfile={logo_scikit} name="SciKit Learn" />
            <Logo imgfile={logo_docker} name="Docker" />
            <Logo imgfile={logo_kubernetes} name="Kubernetes" className="tw-h-20 tw-opacity-50" />
            <Logo imgfile={logo_pandas} name="Pandas" />
            <Logo imgfile={logo_dask} name="Dask" />
            <Logo imgfile={logo_apache} name="Apache" />
            <Logo imgfile={logo_hadoop} name="Hadoop" className="tw-opacity-70" />

        </div>
    </>
)

export default Content
