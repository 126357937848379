// @refresh reset

import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"

import PageWrapper from "../../components/PageWrapper"
import SEO from "../../components/SEO"
import { t, Trans } from "@lingui/macro"

import imgBanner from "../../assets/image/png/service-contracting-banner.png"

import TechStack from "../../sections/technologystack/techstack"

const Contracting = ({ location }) => {
    return (
        <>
            <PageWrapper>
                <SEO
                    pathname={location.pathname}
                    title={t`Contracting`}
                    description={""}
                />




                <div className="tw-px-4 tw-pt-32 tw-pb-16 tw-bg-white section md:tw-pt-32">
                    <div className="tw-container tw-mx-auto lg:tw-max-w-4xl">
                        <h1 className="tw-mb-4 tw-text-6xl tw-font-bold tw-text-left tw-text-black tw-font-title sm:tw-text-center">
                            Contracting
                        </h1>
                        <p className="tw-text-base tw-italic tw-text-left sm:tw-text-center">
                            Die richtige Person am richtigen Ort, zur richtigen Zeit, mit der richtigen Fähigkeiten für ML-Teams ihrer Kunden.
                        </p>
                    </div>
                </div>


                <div className="tw-px-2 tw-pb-8 tw-bg-white section">
                    <div className="tw-container tw-relative tw-mx-auto lg:tw-max-w-4xl">
                        <img src={imgBanner} className="tw-object-cover tw-object-top tw-w-full tw-aspect-video" />
                    </div>
                </div>




                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Künstliche Intelligenz ist heute ein wichtiger Bestandteil hocheffektiver Software. Täglich entwickeln KI-Experten lösungsorientierte Algorithmen zum Einsatz in Produkten und Prozessen. Die Suche und Auswahl kompetenter KI-Experten für den Einsatz in gegenwärtigen und zukünftigen Projekten stellt für Unternehmen ein Wachstumshindernis dar. AMAI stellt erfahrene KI-Experten und sogar ganze Teams flexibel und kurzfristig zur Verfügung, um ihre Projekte zielbewusst voranzutreiben.
                        </p>
                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            Kompetenzfokus
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            AMAI-Experten sind auf ihr Fach spezialisierte Teamplayer, die mit praxiserprobten Kenntnissen und umfassenden Erfahrungen mit ML-Systemen, Projektteams entscheidend vervollständigen. Je nach Rollenbedarf greifen große und mittlere Unternehmen auf AMAI-Experten mit exzellenten Programmierkenntnissen, kommunikativen und analytischen Fähigkeiten, die über das Fach Data Science hinausreichen, zurück. Mittels regelmäßiger Schulungen und Formate zum Erfahrungsaustausch erweitern und verfeinern wir unser Skillset.
                        </p>
                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            Flexibilität
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Je nach Projektbedarf haben Sie die Möglichkeit, flexibel auf verschiedene Spezialisten zurückzugreifen. Das AMAI Team besteht aus Experten auf den Gebieten Data Engineering, Data Science, Machine Learning, MLOps und Python Software Engineering. Für unsere Kunden entfallen dadurch Kosten, die sonst für die Ausbildung, Einarbeitung und langfristige Beschäftigung aufgewendet werden müssen.
                        </p>

                    </div>
                </div>


                <div className="tw-px-4 tw-py-8 tw-bg-white section">
                    <div className="tw-container tw-max-w-xl tw-mx-auto">
                        <h4 className="tw-text-lg tw-font-medium">
                            Kollektive Intelligenz
                        </h4>
                        <p className="tw-mt-3 tw-text-base tw-text-left">
                            Im Gegensatz zu Freelancern, die eine Rolle in einem Projekt als Einzelkämpfer besetzen, sind einzelne AMAI Mitarbeiter weiterhin in einem internen Team eingebunden. In verschiedenen internen Formaten werden gegenwärtige Herausforderungen gemeinschaftlich lösungszentriert analysiert und besprochen. Sie partizipieren somit an dem gesamten AMAI-Experten-Team, obwohl sie nur einen Experten einsetzen.
                        </p>
                    </div>
                </div>


                <div className="tw-px-4 tw-pt-12 tw-pb-12 tw-bg-gray-100 section">
                    <div className="tw-container tw-max-w-4xl tw-mx-auto">

                        <h4 className="tw-text-lg tw-font-medium tw-text-center tw-text-opacity-20">
                            Technologie-Stack
                        </h4>

                        <div>
                            <TechStack />
                        </div>


                    </div>
                </div>



















            </PageWrapper>
        </>
    )
}
export default Contracting
